.disclaimer {
  margin-top: 48px;
  padding: 40px;
  border: 3px solid $primary-purple;
  border-radius: $container-radius;

  /deep/ li {
    line-height: 23px;
    color: #00063b;
    list-style: decimal;
    margin-bottom: 10px;
    @include rtl-sass-prop(padding-left, padding-right, 1em);

    &::marker {
      color: $primary-purple;
    }
  }
}
